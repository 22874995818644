import Axios from 'axios';
import Parse from 'parse';

const clNmSidoMaster = 'SidoMaster';
const clNmSidoDetail = 'SidoDetail';

export const getSidoAll = async () => {
  const res = await Axios.get(
    'https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=*00000000'
  );
  return res;
};

export const saveSidoAll = async (list) => {
  // // ParseSetting.initOleum();

  let arr = [];

  for (let i = 0; i < list.length; i++) {
    let po = new Parse.Object(clNmSidoMaster);

    po.set('code', list[i]['code']);
    po.set('name', list[i]['name']);
    arr.push(po);
  }

  //   console.log('[In saveSidoAll]', arr);
  await Parse.Object.saveAll(arr);
  //   console.log('[res is]', res);
};

export const getBySidoCode = async (item) => {
  const code = item.code;
  let keyCode = '';
  if (code.length > 3) {
    keyCode = `${code.substr(0, 2)}*000000`;
  }

  const res = await Axios.get(
    `https://grpc-proxy-server-mkvo6j4wsq-du.a.run.app/v1/regcodes?regcode_pattern=${keyCode}`
  );
  return res;
};

export const saveDetailSidoAll = async ({ item, list }) => {
  // // ParseSetting.initOleum();

  const upperName = item?.name;
  const upperCode = item?.code;

  const filteredList = list.filter((ele) => ele.code !== item.code);

  const targetList = filteredList.map((ele) => {
    const nm = ele.name;
    const city = nm.replace(upperName, '');
    const obj = {
      code: ele.code,
      name: city,
    };
    return obj;
  });

  // console.log('[item] ', item, targetList);

  let arr = [];
  for (let i = 0; i < targetList.length; i++) {
    let po = new Parse.Object(clNmSidoDetail);

    po.set('code', targetList[i]['code']);
    po.set('name', targetList[i]['name']);
    po.set('upperName', upperName);
    po.set('upperCode', upperCode);

    arr.push(po);
  }

  // console.log('[In saveDetailSidoAll]', arr);
  await Parse.Object.saveAll(arr);
};

export const getSidoDetailAll = async () => {
  // // ParseSetting.initOleum();

  const query = new Parse.Query(clNmSidoDetail);

  const res = await query.findAll();

  const arr = [];
  res.map((item) => {
    const nm = item.get('upperName');
    const cd = item.get('upperCode');
    if (arr.findIndex((ele) => ele.name === nm) === -1) {
      const obj = { name: nm, code: cd };
      arr.push(obj);
    }
    return item;
  });

  return arr;
};

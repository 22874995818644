import { Image } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

function MemberCard({ user }) {
  const userInfo = useMemo(() => {
    if (user) {
      const nickName = user?.get('nickName');
      const profileImage = user?.get('profileImage');

      return (
        <>
          <NameSpan>{nickName}</NameSpan>
          <Image height={50} src={profileImage} alt={nickName} />
        </>
      );
    }
    return '로그인';
  }, [user]);

  return <>{userInfo}</>;
}

export default MemberCard;

const NameSpan = styled.span`
  color: white;
`;

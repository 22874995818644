import { Button, Table } from 'antd';
import React from 'react';

function DataGgList({
  onClickShop,
  handleGet,
  handleDeleteShop,
  dataSource,
  loading,
}) {
  const columns = [
    {
      key: 'name',
      title: '카페명',
      dataIndex: 'name',
      render: (text, data) => (
        <Button onClick={() => onClickShop(data)}>{text}</Button>
      ),
    },
    {
      key: 'key',
      title: 'key',
      dataIndex: 'key',
    },
    {
      key: 'sigunNm',
      title: '시군명',
      dataIndex: 'sigunNm',
      render: (_, data) => (
        <Button onClick={() => handleDeleteShop(data)}>삭제</Button>
      ),
    },
    {
      key: 'address',
      title: '도로명 주소',
      dataIndex: 'address',
    },
    {
      key: 'mapx',
      title: 'mapx (LOGT)',
      dataIndex: 'mapx',
    },
    {
      key: 'mapy',
      title: 'mapy (LAT)',
      dataIndex: 'mapy',
    },
  ];

  return (
    <>
      <Button onClick={handleGet}>Get Coffee Info</Button>
      <Table
        columns={columns}
        dataSource={dataSource}
        key={'objectId'}
        loading={loading}
      />
    </>
  );
}

export default DataGgList;

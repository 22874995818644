import { Col, Row } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';

function KakaoPlace({ id }) {
  const src = useMemo(() => {
    return `https://place.map.kakao.com/${id}`;
  }, [id]);
  return (
    <WrapDiv>
      <Row gutter={16}>
        <Col span={24}>
          <iframe title={'id'} src={src} width={'100%'} height={680}></iframe>
        </Col>
      </Row>
    </WrapDiv>
  );
}

export default KakaoPlace;

const WrapDiv = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`;

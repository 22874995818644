import { Button, Modal } from 'antd';
import styled from 'styled-components';

function SimpleModal({ confirmObj, visible, setVisible }) {
  return (
    <Modal
      title={confirmObj?.title}
      open={visible}
      onCancel={() => setVisible(false)}
      closable
      footer={
        <>
          <Button onClick={() => setVisible(false)}>취소</Button>
          <Button onClick={confirmObj?.confirm}>
            {confirmObj?.okMsg === '' || confirmObj?.okMsg === undefined
              ? '확인'
              : confirmObj?.okMsg}
          </Button>
        </>
      }
    >
      <MessageDiv>{confirmObj?.content}</MessageDiv>
    </Modal>
  );
}

export const ConfirmObj = {
  title: '',
  content: '',
  confirm: () => {},
  okMsg: '확인',
};

export default SimpleModal;

const MessageDiv = styled.div`
  word-break: break-all;
`;

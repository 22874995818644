import Axios from 'axios';
import Parse from 'parse';
// import ParseSetting from './ParseSetting';

const clNmShopManage = 'ShopManage';
// const clNmRestaurantMaster = 'RestaurantMaster';

export const getAllManualShopAsync = async (cityCd) => {
  // // ParseSetting.initHandmadeApp();
  const query = new Parse.Query(clNmShopManage);
  query.equalTo('city_cd', cityCd);
  query.includeAll();

  const res = await query.findAll();

  return res;
};

export const getKakaoLocal = async ({
  keyword = '',
  cityNm = '',
  setIsFind = () => {},
}) => {
  const kakaoRestKey = process.env.REACT_APP_KAKAO_RESTKEY_HAND;
  const url = 'https://dapi.kakao.com/v2/local/search/keyword.json';
  const headers = { Authorization: `KakaoAK ${kakaoRestKey}` };
  const totalKeyword = `${keyword}+${cityNm}`;
  const queryParam = { query: totalKeyword };
  const res = await Axios.get(url, { headers, params: queryParam });

  setIsFind(true);

  console.log('[res is] ', res);
  return res;
};

export const statusCompanyAPI = async ({ bno = '' }) => {
  const serviceKey =
    'uyqmTDA5TukQCDZUDWDWYH%2FJ18IC8m%2BfjQCHZe%2FGjL7koOZALjU%2FpCpjyLdLOgshHYCp%2BjLvUKZHzJZURFAQxA%3D%3D';
  // const unEncodedServiceKey =
  //   'uyqmTDA5TukQCDZUDWDWYH/J18IC8m+fjQCHZe/GjL7koOZALjU/pCpjyLdLOgshHYCp+jLvUKZHzJZURFAQxA==';
  const bodyParam = {
    b_no: [bno],
  };
  const data = JSON.stringify(bodyParam);
  const url = `http://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${serviceKey}&returnType=JSON`;
  const res = await Axios.post(url, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  console.log('[statusCompanyAPI]', res);
  return res;
};

export const validateCompanyAPI = async ({
  bno = '',
  startDt = '',
  name = '',
  shopNm = '',
}) => {
  const serviceKey =
    'uyqmTDA5TukQCDZUDWDWYH%2FJ18IC8m%2BfjQCHZe%2FGjL7koOZALjU%2FpCpjyLdLOgshHYCp%2BjLvUKZHzJZURFAQxA%3D%3D';
  // const unEncodedServiceKey =
  //   'uyqmTDA5TukQCDZUDWDWYH/J18IC8m+fjQCHZe/GjL7koOZALjU/pCpjyLdLOgshHYCp+jLvUKZHzJZURFAQxA==';
  const bodyParam = {
    businesses: [
      {
        b_no: bno,
        start_dt: startDt,
        p_nm: name,
        b_nm: shopNm,
      },
    ],
  };
  const data = JSON.stringify(bodyParam);
  const url = `http://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=${serviceKey}&returnType=JSON`;
  const res = await Axios.post(url, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  console.log('[validateCompanyAPI]', res);
  return res;
};

export const updateUserSetCity = async ({ doCd, cityCd, cityNm }) => {
  const po = await Parse.User.current();
  po.set('doCd', doCd);
  po.set('cityCd', cityCd);
  po.set('cityNm', cityNm);

  await po.save();
};

export const registerShopManaual = async (item) => {
  console.log('[registerShopManaual]', item);
  // // ParseSetting.initHandmadeApp();

  const po = new Parse.Object(clNmShopManage);
  const currentUser = await Parse.User.current();

  const bName = item?.b_nm;
  const bNo = item?.b_no;
  const pName = item?.p_nm;
  const bStt = item?.b_stt;
  const taxType = item?.tax_type;

  console.log('[currentUser]', currentUser);

  po.set('admin', currentUser);
  po.set('business_man', item);
  po.set('b_name', bName);
  po.set('p_name', pName);
  po.set('b_no', bNo);
  po.set('b_stt', bStt);
  po.set('tax_type', taxType);
  po.set('managers', [currentUser]);
  po.set('shop_imageg', '');
  po.set('shop_images', []);
  po.set('pplType', 'M');
  po.set('is_ready', true);
  po.set('shop_sido', item?.shop_sido);
  po.set('city_cd', item?.city_cd);

  const res = await po.save();
  console.log('[save is]', res);
  return res;
};

export const getCurrentUserInfo = async () => {
  return await Parse.User.current();
};

export const deleteShopMangeByIsReady = async (delteKeys) => {
  // // ParseSetting.initHandmadeApp();

  const arrs = [];
  for (let i = 0; i < delteKeys.length; i++) {
    const po = new Parse.Object(clNmShopManage);
    po.set('objectId', delteKeys[i]);
    arrs.push(po);
  }

  return await Parse.Object.destroyAll(arrs);
};

export const updateShopMngByKakao = async ({ oid, item }) => {
  const po = new Parse.Object(clNmShopManage);
  po.set('objectId', oid);
  po.set('is_set_portal', true);
  po.set('portal', 'KAKAO');

  po.set('kakaoId', item?.id);
  po.set('category_name', item?.category_name);
  po.set('phone', item?.phone);
  po.set('road_address_name', item?.road_address_name);
  po.set('address_name', item?.address_name);
  po.set('lng', item?.x);
  po.set('lat', item?.y);

  await po.save();
};

export const getKakaoPlace = async ({ kakaoId = '' }) => {
  const url = `https://place.map.kakao.com/main/v/${kakaoId}`;
  const res = await Axios.get(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-content-type-options': 'nosniff',
    },
  });

  return res;
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const uploadFile = async (oid, file) => {
  // console.log('[uploadFile]', oid, file);
  const fileName = file?.name;
  const b64 = await getBase64(file);
  // console.log('[uploadFile] ', b64);
  const newImage = new Parse.File(fileName, { base64: b64 });
  const res = await newImage.save();
  const url = res?._url;

  console.log('[uploadFile] ', url);

  if (url) {
    const po = new Parse.Object(clNmShopManage);
    po.set('objectId', oid);
    po.set('shop_image', url);
    await po.save();
  }

  return url;
};

export const doList = [
  { label: '서울특별시', value: '1' },
  { label: '부산광역시', value: '91' },
  { label: '인천광역시', value: '92' },
  { label: '대구광역시', value: '93' },
  { label: '대전광역시', value: '94' },
  { label: '광주광역시', value: '95' },
  { label: '울산광역시', value: '96' },
  { label: '세종특별자치시', value: '97' },
  { label: '제주특별자치도', value: '98' },
  { label: '경기도', value: '11' },
  { label: '강원도', value: '21' },
  { label: '충청북도', value: '31' },
  { label: '충청남도', value: '41' },
  { label: '경상북도', value: '51' },
  { label: '경상남도', value: '61' },
  { label: '전라북도', value: '71' },
  { label: '전라남도', value: '81' },
];

export const cityTotalList = [
  {
    code: '1',
    list: [
      { label: '강남구', value: '11100' },
      { label: '강동구', value: '11200' },
      { label: '강북구', value: '11300' },
      { label: '강서구', value: '11400' },
      { label: '관악구', value: '11500' },
      { label: '광진구', value: '11600' },
      { label: '구로구', value: '11700' },
      { label: '금천구', value: '11800' },
      { label: '노원구', value: '11900' },
      { label: '도봉구', value: '12100' },
      { label: '동대문구', value: '12200' },
      { label: '동작구', value: '12300' },
      { label: '마포구', value: '12400' },
      { label: '서대문구', value: '12500' },
      { label: '서초구', value: '12600' },
      { label: '성동구', value: '12700' },
      { label: '성북구', value: '12800' },
      { label: '송파구', value: '12900' },
      { label: '양천구', value: '13100' },
      { label: '영등포구', value: '13200' },
      { label: '용산구', value: '13300' },
      { label: '은평구', value: '13400' },
      { label: '종로구', value: '13500' },
      { label: '중구', value: '13600' },
      { label: '중랑구', value: '13700' },
    ],
  },
  {
    code: '91',
    list: [
      { label: '중구', value: '91010' },
      { label: '서구', value: '91020' },
      { label: '동구', value: '91030' },
      { label: '영도구', value: '91040' },
      { label: '부산진구', value: '91050' },
      { label: '동래구', value: '91060' },
      { label: '남구', value: '91070' },
      { label: '북구', value: '91080' },
      { label: '해운대구', value: '91090' },
      { label: '사하구', value: '91100' },
      { label: '금정구', value: '91110' },
      { label: '강서구', value: '91120' },
      { label: '연제구', value: '91130' },
      { label: '수영구', value: '91140' },
      { label: '사상구', value: '91150' },
      { label: '기장군', value: '91160' },
    ],
  },
  {
    code: '92',
    list: [
      { label: '강화군', value: '92010' },
      { label: '옹진군', value: '92020' },
      { label: '중구', value: '92030' },
      { label: '동구', value: '92040' },
      { label: '미추홀구', value: '92050' },
      { label: '연수구', value: '92060' },
      { label: '남동구', value: '92070' },
      { label: '부평구', value: '92080' },
      { label: '계양구', value: '92090' },
      { label: '서구', value: '92100' },
    ],
  },
  {
    code: '93',
    list: [
      { label: '중구', value: '93010' },
      { label: '동구', value: '93020' },
      { label: '서구', value: '93030' },
      { label: '남구', value: '93040' },
      { label: '북구', value: '93050' },
      { label: '수서구', value: '93060' },
      { label: '달서구', value: '93070' },
      { label: '달성군', value: '93080' },
    ],
  },
  {
    code: '94',
    list: [
      { label: '동구', value: '94010' },
      { label: '중구', value: '94020' },
      { label: '서구', value: '94030' },
      { label: '유성구', value: '94040' },
      { label: '대덕구', value: '94050' },
    ],
  },
  {
    code: '95',
    list: [
      { label: '동구', value: '95010' },
      { label: '서구', value: '95020' },
      { label: '남구', value: '95030' },
      { label: '북구', value: '95040' },
      { label: '광산구', value: '95050' },
    ],
  },
  {
    code: '96',
    list: [
      { label: '중구', value: '96010' },
      { label: '남구', value: '96020' },
      { label: '동구', value: '96030' },
      { label: '북구', value: '96040' },
      { label: '울주군', value: '96050' },
    ],
  },
  {
    code: '97',
    list: [
      { label: '조치원읍', value: '97010' },
      { label: '연기면', value: '97020' },
      { label: '연동면', value: '97030' },
      { label: '부강면', value: '97040' },
      { label: '금남면', value: '97050' },
      { label: '장군면', value: '97060' },
      { label: '연서면', value: '97070' },
      { label: '전의면', value: '97080' },
      { label: '전동면', value: '97090' },
      { label: '소정면', value: '97100' },
      { label: '한솔동', value: '97110' },
      { label: '도담동', value: '97120' },
      { label: '아름동', value: '97130' },
      { label: '종촌동', value: '97140' },
      { label: '고운동', value: '97150' },
      { label: '보람동', value: '97160' },
      { label: '새롬동', value: '97170' },
      { label: '대평동', value: '97180' },
      { label: '소담동', value: '97190' },
      { label: '다정동', value: '97200' },
      { label: '해밀동', value: '97210' },
      { label: '반곡동', value: '97220' },
      { label: '나성동', value: '97230' },
      { label: '어진동', value: '97240' },
    ],
  },
  {
    code: '98',
    list: [
      { label: '제주시', value: '98010' },
      { label: '서귀포시', value: '98020' },
      { label: '한경', value: '98030' },
      { label: '한림', value: '98040' },
      { label: '애월', value: '98050' },
      { label: '조천', value: '98060' },
      { label: '구좌', value: '98070' },
      { label: '성산', value: '98080' },
      { label: '표선', value: '98090' },
      { label: '남원', value: '98100' },
      { label: '안덕', value: '98130' },
      { label: '대정', value: '98140' },
      { label: '우도', value: '98150' },
      { label: '가파도', value: '98160' },
      { label: '마라도', value: '98170' },
      { label: '추자도', value: '98180' },
    ],
  },
  {
    code: '11',
    list: [
      { label: '가평군', value: '41820' },
      { label: '고양시', value: '41280' },
      { label: '과천시', value: '41290' },
      { label: '광명시', value: '41210' },
      { label: '광주시', value: '41610' },
      { label: '구리시', value: '41310' },
      { label: '군포시', value: '41410' },
      { label: '김포시', value: '41570' },
      { label: '남양주시', value: '41360' },
      { label: '동두천시', value: '41250' },
      { label: '부천시', value: '41190' },
      { label: '성남시', value: '41130' },
      { label: '수원시', value: '41110' },
      { label: '시흥시', value: '41390' },
      { label: '안산시', value: '41270' },
      { label: '안성시', value: '41550' },
      { label: '안양시', value: '41170' },
      { label: '양주시', value: '41630' },
      { label: '양평군', value: '41830' },
      { label: '여주시', value: '41670' },
      { label: '연천군', value: '41800' },
      { label: '오산시', value: '41370' },
      { label: '용인시', value: '41460' },
      { label: '의왕시', value: '41430' },
      { label: '의정부시', value: '41150' },
      { label: '이천시', value: '41500' },
      { label: '파주시', value: '41480' },
      { label: '평택시', value: '41220' },
      { label: '포천시', value: '41650' },
      { label: '하남시', value: '41450' },
      { label: '화성시', value: '41590' },
    ],
  },
  {
    code: '21',
    list: [
      { label: '춘천시', value: '21820' },
      { label: '원주시', value: '21280' },
      { label: '강릉시', value: '21290' },
      { label: '동해시', value: '21210' },
      { label: '태백시', value: '21610' },
      { label: '속초시', value: '21310' },
      { label: '삼척시', value: '21410' },
      { label: '홍천군', value: '21570' },
      { label: '횡성군', value: '21360' },
      { label: '영월군', value: '21250' },
      { label: '평창군', value: '21190' },
      { label: '정선군', value: '21130' },
      { label: '철원군', value: '21110' },
      { label: '화천군', value: '21390' },
      { label: '양구군', value: '21270' },
      { label: '인제군', value: '21550' },
      { label: '고성군', value: '21170' },
      { label: '양양군', value: '21630' },
    ],
  },
  {
    code: '31',
    list: [
      { label: '청주시', value: '31820' },
      { label: '충주시', value: '31280' },
      { label: '제천시', value: '31290' },
      { label: '보은군', value: '31210' },
      { label: '옥천군', value: '31610' },
      { label: '영동군', value: '31310' },
      { label: '증평군', value: '31410' },
      { label: '진천군', value: '31570' },
      { label: '괴산군', value: '31360' },
      { label: '음성군', value: '31250' },
      { label: '단양군', value: '31190' },
    ],
  },
  {
    code: '41',
    list: [
      { label: '천안시', value: '141820' },
      { label: '공주시', value: '141280' },
      { label: '보령시', value: '141290' },
      { label: '아산시', value: '141210' },
      { label: '서산시', value: '141610' },
      { label: '논산시', value: '141310' },
      { label: '계룡시', value: '141410' },
      { label: '당진시', value: '141570' },
      { label: '금산군', value: '141360' },
      { label: '부여군', value: '141250' },
      { label: '서천군', value: '141190' },
      { label: '청양군', value: '141130' },
      { label: '홍성군', value: '141110' },
      { label: '예산군', value: '141390' },
      { label: '태안군', value: '141270' },
    ],
  },
  {
    code: '51',
    list: [
      { label: '포항시', value: '51200' },
      { label: '경주시', value: '51100' },
      { label: '김천시', value: '51201' },
      { label: '안동시', value: '51202' },
      { label: '구미시', value: '51203' },
      { label: '영주시', value: '51204' },
      { label: '영천시', value: '51205' },
      { label: '상주시', value: '51206' },
      { label: '문경시', value: '51207' },
      { label: '경산시', value: '51208' },
      { label: '의성군', value: '51019' },
      { label: '청송군', value: '51029' },
      { label: '영양군', value: '51039' },
      { label: '영덕군', value: '510049' },
      { label: '청도군', value: '51059' },
      { label: '고령군', value: '51069' },
      { label: '성주군', value: '51079' },
      { label: '칠곡군', value: '51089' },
      { label: '예천군', value: '51119' },
      { label: '봉화군', value: '51129' },
      { label: '울진군', value: '51139' },
      { label: '울릉군', value: '51999' },
    ],
  },
  {
    code: '61',
    list: [
      { label: '창원시', value: '61200' },
      { label: '김해시', value: '61100' },
      { label: '진주시', value: '61201' },
      { label: '양산시', value: '61202' },
      { label: '거제시', value: '61203' },
      { label: '통영시', value: '61204' },
      { label: '통영시', value: '61204' },
      { label: '사천시', value: '61205' },
      { label: '밀양시', value: '61206' },
      { label: '함안군', value: '61019' },
      { label: '거창군', value: '61029' },
      { label: '창녕군', value: '61039' },
      { label: '고성군', value: '610049' },
      { label: '하동군', value: '61059' },
      { label: '합천군', value: '61069' },
      { label: '남해군', value: '61079' },
      { label: '함양군', value: '61089' },
      { label: '산청군', value: '61119' },
      { label: '의령군', value: '61129' },
    ],
  },
  {
    code: '71',
    list: [
      { label: '전주시', value: '71200' },
      { label: '익산시', value: '71100' },
      { label: '군산시', value: '71201' },
      { label: '정읍시', value: '71202' },
      { label: '김제시', value: '71203' },
      { label: '남원시', value: '71204' },
      { label: '완주군', value: '71019' },
      { label: '고창군', value: '71029' },
      { label: '부안군', value: '71039' },
      { label: '임실군', value: '710049' },
      { label: '순창군', value: '71059' },
      { label: '진안군', value: '71069' },
      { label: '무주군', value: '71079' },
      { label: '장수군', value: '71089' },
    ],
  },
  {
    code: '81',
    list: [
      { label: '목포시', value: '81200' },
      { label: '여수시', value: '81201' },
      { label: '순천시', value: '81202' },
      { label: '나주시', value: '81203' },
      { label: '광양시', value: '81204' },
      { label: '담양군', value: '81205' },
      { label: '곡성군', value: '81206' },
      { label: '구례군', value: '81207' },
      { label: '고흥군', value: '81208' },
      { label: '보성군', value: '81209' },
      { label: '화순군', value: '81210' },
      { label: '장흥군', value: '81211' },
      { label: '강진군', value: '81212' },
      { label: '해남군', value: '81213' },
      { label: '영암군', value: '81214' },
      { label: '무안군', value: '81215' },
      { label: '함평군', value: '81216' },
      { label: '영광군', value: '81217' },
      { label: '장성군', value: '81218' },
      { label: '완도군', value: '81219' },
      { label: '진도군', value: '81220' },
      { label: '신안군', value: '81221' },
    ],
  },
];

import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, Col, Image, Row, Space, Table, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import KakaoMap from 'components/KakaoMap';
import { updateShopMngByKakao, uploadFile } from 'apis/ShopApi';

import SimpleModal, { ConfirmObj } from 'components/SimpleModal';

function ShopCard({ shop = {}, data }) {
  const NO_IMAGE = process.env.REACT_APP_NO_IMAGE_BASE64;
  const [visibleSM, setVisibleSM] = useState(false);
  const [simpleObj, setSimpleObj] = useState(ConfirmObj);

  // console.log('[CHECK]', shopNm, shop?.b_name);
  const shopNm = useMemo(() => {
    return shop?.b_name;
  }, [shop]);

  const dataSource = useMemo(() => {
    if (data?.status === 200) {
      if (Array.isArray(data?.data?.documents)) {
        return data?.data?.documents;
      }
    }
    return [];
  }, [data]);

  // console.log('[data is]', data);
  const geopoint = useMemo(() => {
    const gpoint = shop?.['geoPoint'];
    return { lat: gpoint?.['latitude'], lng: gpoint?.['longitude'] };
  }, [shop]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // eslint-disable-next-line no-unused-vars
  const [previewOpen, setPreviewOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [previewImage, setPreviewImage] = useState('');

  const [fileList, setFileList] = useState(
    shop?.['shop_image'] !== undefined
      ? [
          {
            uid: shop?.['objectId'],
            name: shop?.['b_name'],
            status: 'done',
            url: shop?.['shop_image'],
          },
        ]
      : []
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type='button'
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const shopId = useMemo(() => {
    return shop?.key;
  }, [shop]);

  const handleUpdateByKakao = useCallback(
    (item) => {
      simpleObj.title = 'Update';
      simpleObj.content = '정보를 반영하시겠습니까?';
      simpleObj.confirm = async () => {
        const oid = shopId;
        if (oid) {
          console.log('[handleUpdateByKakao ]', item, oid);
          updateShopMngByKakao({ oid, item });
        }
        setVisibleSM(false);
      };
      setSimpleObj(simpleObj);
      setVisibleSM(true);
    },
    [shopId, simpleObj]
  );

  const columns = useMemo(() => {
    return [
      {
        title: '업체명',
        dataIndex: 'place_name',
        width: 200,
      },
      {
        title: '카테고리',
        dataIndex: 'category_name',
      },
      {
        title: '연락처',
        dataIndex: 'phone',
      },
      {
        title: '도로명 주소',
        dataIndex: 'road_address_name',
      },
      {
        title: 'X',
        dataIndex: 'x',
      },
      {
        title: 'Y',
        dataIndex: 'y',
      },
      {
        title: 'Action',
        dataIndex: 'phone',
        render: (_, record) => {
          return (
            <Button
              disabled={shop?.['is_set_portal'] === true}
              onClick={() => handleUpdateByKakao(record)}
            >
              Set
            </Button>
          );
        },
      },
    ];
  }, [handleUpdateByKakao, shop]);
  // console.log('[is_set_portal]', shop);

  return (
    <Card title={`업체 정보 [${shopNm}]`} key={'shop'}>
      <Space direction='vertical'>
        <Row>
          <Space direction='horizontal' size={'middle'}>
            <Col>
              {shop?.['shop_image'] === undefined ? (
                <Upload
                  action={(file) => uploadFile(shop?.['key'], file)}
                  listType='picture-circle'
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              ) : (
                <Image
                  width={320}
                  height={210}
                  src={shop?.['shop_image']}
                  fallback={NO_IMAGE}
                />
              )}
            </Col>
            <Col>
              <Col>{shop?.['shop_desc']}</Col>
            </Col>
            <Col>
              {shop?.['is_set_portal'] === true ? 'Portal Set' : 'Not Set'}
            </Col>
          </Space>
        </Row>
        <Row>
          <Table
            rowKey={'phone'}
            title={() => 'KAKAO Local Informatin'}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </Row>
        <Row>{JSON.stringify(geopoint)}</Row>
        <Row>
          <KakaoMap lat={geopoint?.lat} lng={geopoint?.lng} />
        </Row>
      </Space>
      <SimpleModal
        confirmObj={simpleObj}
        visible={visibleSM}
        setVisible={setVisibleSM}
      />
    </Card>
  );
}

export default ShopCard;

import { Card, Row, Table, Button } from 'antd';
import { registerShopManaual } from 'apis/ShopApi';
import React, { useCallback, useMemo, useState } from 'react';
import SimpleModal from './SimpleModal';
import styled from 'styled-components';

function TaxInfo({ data = {}, refetch, setVisible, setParam }) {
  const [formObj, setFormObj] = useState({
    title: '',
    content: '',
    confirm: '',
  });
  const [visibleSimple, setVisbleSimple] = useState(false);

  const handleApplyShop = useCallback(
    async (item) => {
      formObj.title = '등록';
      formObj.content = `선택한 ${item.b_nm}\n 을 등록하시겠습니까?`;
      formObj.confirm = async () => {
        const cityNm = sessionStorage.getItem('cityNm');
        const cityCd = sessionStorage.getItem('cityCd');
        const obj = {
          ...item,
          city_cd: cityCd,
          shop_sido: cityNm,
        };
        console.log('[handleApplyShop]', obj);
        await registerShopManaual(obj);
        refetch();
        setVisible(false);
        setVisbleSimple(false);

        setParam({
          bno: '',
          startDt: '',
          name: '',
          shopNm: '',
        });
      };

      setFormObj(formObj);
      setVisbleSimple(true);
    },
    [formObj, refetch, setParam, setVisible]
  );

  const columns = useMemo(() => {
    return [
      { title: '업체명', dataIndex: 'b_nm' },
      { title: '대표', dataIndex: 'p_nm' },
      { title: '사업자 등록번호', dataIndex: 'b_no' },
      { title: '과세유형', dataIndex: 'tax_type' },
      { title: '납세자상태', dataIndex: 'b_stt' },
      { title: '창업일자', dataIndex: 'start_dt' },
      {
        title: 'valid',
        dateIndex: 'valid',
        render: (_, record) => {
          // console.log('[text is]', record);
          return record?.valid === '01' ? (
            <StatusStrong status={record?.valid}>성공</StatusStrong>
          ) : (
            <StatusStrong status={record?.valid}>실패</StatusStrong>
          );
        },
      },
      {
        title: 'valid msg',
        dateIndex: 'valid_msg',
        render: (_, record) => {
          // console.log('[record]', record);
          if (record?.valid === '02') {
            return record?.valid_msg;
          }
          return 'SUCCESS';
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, record) => {
          return record?.valid === '02' ? (
            <></>
          ) : (
            <Button onClick={() => handleApplyShop(record)}>등록</Button>
          );
        },
      },
    ];
  }, [handleApplyShop]);

  const dataSource = useMemo(() => {
    // console.log(' >> ', data?.data?.data);
    if (Array.isArray(data?.data?.data)) {
      const arrs = [];
      data?.data?.data.map((item) => {
        const rp = item?.request_param;
        const st = item?.status;
        const obj = {
          b_nm: rp?.b_nm,
          p_nm: rp?.p_nm,
          b_no: item?.b_no,
          tax_type: st?.tax_type,
          b_stt: st?.b_stt,
          start_dt: rp?.start_dt,
          valid: item?.valid,
          valid_msg: item?.valid_msg,
        };
        arrs.push(obj);
        return item;
      });
      return arrs;
    }
    return [];
  }, [data]);

  return (
    <>
      <Row>
        <strong>API Call Status : {data?.statusText}</strong>
        <Card></Card>
      </Row>
      <Row>
        <Table rowKey={'b_no'} columns={columns} dataSource={dataSource} />
      </Row>
      <SimpleModal
        visible={visibleSimple}
        setVisible={setVisbleSimple}
        confirmObj={formObj}
      />
    </>
  );
}

export default TaxInfo;

const StatusStrong = styled.strong`
  color: ${(props) => (props.status === '01' ? 'green' : 'red')};
`;

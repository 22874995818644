import Header from 'components/Header';
import Footer from 'components/Footer';
import React, { useEffect, useState } from 'react';
import DivSection from './food_everline/DivSection';
import * as API from 'store/apis/apps/food_everline/food_everline_api';
import { Spin } from 'antd';

function FoodEverline() {
  const [stationList, setStationList] = useState([]);
  async function getStations() {
    let glist = await API.getStations();
    let mlist = [];
    for (let i = 0; i < glist.length; i++) {
      let item = glist[i];
      let arr = await API.getRestaruantMaster(item['station_cd']);
      item['shops'] = arr;
      mlist.push(item);
    }

    setStationList(mlist);
  }

  useEffect(() => {
    getStations();
  }, []);
  return (
    <div id='page' className='s-pagewrap ss-home'>
      <Header />
      <section className='s-content'>
        <section id='intro' className='s-intro'>
          <div className='row s-intro__content width-sixteen-col'>
            <div className='column lg-12 s-intro__content-inner grid-block'>
              <div className='s-intro__content-text'>
                <div className='s-intro__content-pretitle text-pretitle'>
                  음식탐험 에버라인
                </div>
                <h5 className='s-intro__content-title tway'>
                  에버라인 15개역의 음식점에 대한 일상 나누기
                </h5>
              </div>{' '}
            </div>
          </div>
        </section>
      </section>
      {stationList.length === 0 ? (
        <Spin />
      ) : (
        stationList.map((item) => (
          <DivSection
            key={item['objectId']}
            stationNm={item['name_ko']}
            stationEn={item['name_en']}
            descList={item['desc']}
            imgUrl={item['img_url']}
            shops={item['shops']}
          />
        ))
      )}
      <Footer />
    </div>
  );
}

export default FoodEverline;

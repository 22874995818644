import * as foodAPI from 'apis/FoodApi';
import { takeEvery } from 'redux-saga/effects';
import {
  createPromiseSaga,
  handleAsyncActions,
  reducerUtils,
} from 'utils/asyncUtils';

// 액션 타입
const GET_ALL_SHOP = 'FOOD/GET_ALL_SHOP';
const GET_ALL_SHOP_SUCCESS = 'FOOD/GET_ALL_SHOP_SUCCESS';
const GET_ALL_SHOP_ERROR = 'FOOD/GET_ALL_SHOP_ERROR';

// 액션 생성 함수
export const getAllShop = () => ({ type: GET_ALL_SHOP });

const getAllShopSaga = createPromiseSaga(GET_ALL_SHOP, foodAPI.getAllFoodShop);

export function* foodSaga() {
  yield takeEvery(GET_ALL_SHOP, getAllShopSaga);
}

// 초기값
const initialState = {
  shops: reducerUtils.initial(),
};

export default function food(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SHOP:
    case GET_ALL_SHOP_SUCCESS:
    case GET_ALL_SHOP_ERROR:
      return handleAsyncActions(GET_ALL_SHOP, 'shops', true)(state, action);
    default:
      return state;
  }
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Space } from 'antd';
import styled from 'styled-components';

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <CenterDiv>
      <Space direction='vertical'>
        <Row gutter={16}>
          <NotSvg />
        </Row>
        <Row gutter={16}>
          <Button onClick={() => navigate('/')}>Go Home</Button>
        </Row>
      </Space>
    </CenterDiv>
  );
}

export default NotFoundPage;

const CenterDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  vertical-align: center;
  align-items: center;
`;

const SvgDiv = styled.div`
  display: flex;
  height: 100%;
  width: 800px;
  .cls-1 {
    fill: #d9cfff;
  }
  .cls-2 {
    fill: #1d1e1b;
  }
  .cls-3 {
    fill: #fff;
  }
  .cls-4 {
    fill: #1d1d1b;
  }
  .cls-5 {
    fill: #ffcdb2;
  }
  .cls-6 {
    fill: #ffad00;
  }
  .cls-7 {
    fill: #e89d00;
  }
  .cls-8 {
    fill: #ffc140;
  }
`;

const NotSvg = () => (
  <SvgDiv>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1000 1000'
      width={'current'}
      height={'current'}
    >
      <g id='_404' data-name='404'>
        <g id='_0' data-name='0'>
          <path
            class='cls-1'
            d='M561.66,317.5c-43.58-13.1-84.42-3.74-121.42,27.83-30.35,26.11-52.31,61.2-65.26,104.29-15.06,50.13-19.56,94-13.36,130.6,8.8,49.9,37.35,82.44,84.85,96.71,44.19,13.28,83.23,5.58,116-22.9,31.26-27.13,57.06-74,76.7-139.38,12.5-41.58,13.58-80.4,3.23-115.41C630,357.36,602.81,329.86,561.66,317.5Zm-96.12,296c-20.91-6.28-33.34-20.4-38-43.17-5.16-25.09-1-60.38,12.37-104.88,8-26.62,21.18-48.73,39.18-65.71,20.36-19.19,41.05-25.27,63.25-18.6,19.94,6,32.13,18,37.25,36.67,5.6,20.45,2.94,49.1-7.89,85.15-10.52,35-23.59,62.8-38.86,82.65C512.61,611.88,490.6,621,465.54,613.47Z'
          />
          <path
            class='cls-2'
            d='M486.93,684.75A141.79,141.79,0,0,1,446,678.37c-48.09-14.45-77-47.39-85.9-97.89-6.24-36.76-1.72-80.93,13.41-131.29,13-43.37,35.14-78.7,65.71-105,37.41-31.93,78.73-41.39,122.84-28.14,41.66,12.52,69.17,40.36,81.77,82.76,10.43,35.29,9.35,74.4-3.23,116.26C620.92,580.71,595,627.84,563.48,655.16,540.79,674.86,515.15,684.75,486.93,684.75Zm37.31-371.48c-29.32,0-57.12,11.1-83,33.21-30.13,25.91-51.93,60.76-64.8,103.57C361.42,500,356.94,543.67,363.1,580c8.69,49.28,36.88,81.43,83.8,95.53,43.67,13.12,82.23,5.52,114.61-22.6,31-26.94,56.7-73.6,76.25-138.68,12.41-41.29,13.5-79.83,3.23-114.55-12.29-41.36-39.13-68.53-79.76-80.74A128.37,128.37,0,0,0,524.24,313.27ZM482.09,617.54a58.79,58.79,0,0,1-17-2.63c-21.48-6.46-34.26-20.95-39.06-44.31-5.21-25.34-1-60.87,12.41-105.61,8.07-26.87,21.39-49.2,39.58-66.37C498.83,379,520,372.83,542.75,379.67c20.48,6.15,33,18.49,38.26,37.72,5.68,20.72,3,49.65-7.9,86-10.57,35.17-23.72,63.13-39.1,83.12C518,607.27,500.93,617.53,482.09,617.54ZM525.3,380c-15.53,0-30.44,6.88-45.2,20.79-17.81,16.8-30.85,38.69-38.77,65.05C428,510.11,423.88,545.15,429,570c4.55,22.18,16.65,35.93,37,42,24.39,7.33,45.87-1.63,65.66-27.38,15.16-19.7,28.15-47.34,38.61-82.16,10.75-35.78,13.4-64.15,7.88-84.32-5-18.15-16.83-29.8-36.23-35.64A57.41,57.41,0,0,0,525.3,380Z'
          />
          <path
            class='cls-3'
            d='M613.2,550.86a5.5,5.5,0,0,1-5.19-7.32c22.08-63,18.78-105.72,12.13-130.45-7.15-26.57-19.6-38.6-19.72-38.72a5.5,5.5,0,0,1,7.51-8c.59.55,14.49,13.74,22.57,42.95,7.29,26.34,11.1,71.65-12.11,137.9A5.5,5.5,0,0,1,613.2,550.86Z'
          />
          <path
            class='cls-3'
            d='M430.1,659.78a5.42,5.42,0,0,1-2.59-.66c-30.73-16.46-49.7-50.78-54.86-99.24A279.52,279.52,0,0,1,374,492a5.5,5.5,0,0,1,10.86,1.79c-.2,1.2-18.88,119.93,47.86,155.68a5.5,5.5,0,0,1-2.61,10.35Z'
          />
          <path
            class='cls-4'
            d='M668.94,772.33l-.32,0c-59.67-9.48-99.7-46.2-122.78-75.33-25-31.52-36-61.24-36.13-61.54a2,2,0,0,1,3.76-1.38c.11.3,11,29.51,35.59,60.53,22.62,28.54,61.84,64.5,120.19,73.77a2,2,0,0,1-.31,4Z'
          />
          <path
            class='cls-4'
            d='M665.82,910.7a2,2,0,0,1-1.11-.34c-37.66-25.28-70.71-54.73-98.23-87.53a416,416,0,0,1-55.16-84.53c-24.83-50.86-29.54-88.76-29.59-89.14a2,2,0,1,1,4-.47c.05.37,4.71,37.73,29.3,88,22.69,46.4,67.46,113.61,151.94,170.33a2,2,0,0,1-1.12,3.66Z'
          />
          <path
            class='cls-4'
            d='M682,431h-.07a2,2,0,0,1-1.93-2.07c1.62-43.63-8.9-77.74-31.25-101.38C618.14,295.19,575.39,294,575,294a2,2,0,0,1-2-2,2,2,0,0,1,2-2c.44,0,44.79,1.18,76.58,34.76,23.14,24.45,34,59.55,32.38,104.31A2,2,0,0,1,682,431Z'
          />
        </g>
        <g id='hande'>
          <path
            class='cls-5'
            d='M522.54,311.78a46.47,46.47,0,0,0-4-8.33c-12.86-21.35-70.89-37.09-70.89-37.09L368.09,309l103.59,15.3A117.24,117.24,0,0,1,522.54,311.78Z'
          />
          <path
            class='cls-2'
            d='M472,326.38l-.62-.09L361.81,310.11l85.56-45.89.77.21c2.4.65,58.94,16.2,72.07,38a48.16,48.16,0,0,1,4.22,8.7l.92,2.62-2.78,0a115.79,115.79,0,0,0-50,12.32Zm-97.65-18.47,97,14.33a119.91,119.91,0,0,1,48.27-12.38,51.23,51.23,0,0,0-2.83-5.38c-11.53-19.13-62.11-34-68.94-36Z'
          />
          <path
            class='cls-5'
            d='M369.49,309.94l9.19,10.1s-6.28,14.54,5.28,49.81c18.28,55.73,58.41,33.27,58.41,33.27,22,11.43,37-12.07,37-12.07s31.95,25.5,38.07-20.41c3.5-26.26-20.38-52.56-42.11-71.06'
          />
          <path
            class='cls-2'
            d='M424,409.11a35.3,35.3,0,0,1-12.91-2.39c-12.61-4.91-22.2-17.16-28.51-36.41-10.13-30.89-6.77-46.14-5.57-49.93L368.38,311l2.22-2,9.84,10.81-.38.89c-.06.14-5.86,14.63,5.33,48.75,6,18.33,15,29.95,26.71,34.54,15,5.84,29.4-2,29.54-2.11l.7-.39.72.37c20.59,10.67,34.42-10.63,35-11.54l.9-1.41,1.3,1c.13.1,12.49,9.79,22.55,5.74,6.83-2.76,11.24-11.23,13.1-25.18,2.73-20.44-11.27-43.89-41.6-69.72l1.95-2.28c31.15,26.53,45.49,50.88,42.63,72.39-2,15.12-7.05,24.39-15,27.58-9.74,3.91-20.59-2.68-24.25-5.25-2.86,3.81-12.11,14.58-25.3,14.59a26.92,26.92,0,0,1-12-2.95A43,43,0,0,1,424,409.11Z'
          />
          <path
            class='cls-4'
            d='M478.76,392.34h-.19a1.51,1.51,0,0,1-1.3-1.68c0-.27,3.15-26.82-16.69-50a1.5,1.5,0,0,1,2.27-1.95c20.74,24.24,17.54,51.21,17.4,52.34A1.5,1.5,0,0,1,478.76,392.34Z'
          />
          <path
            class='cls-4'
            d='M442.74,404.6a1.47,1.47,0,0,1-1.22-.64c-.72-1-17.52-25.1-17.77-53a1.51,1.51,0,0,1,1.49-1.52h0a1.5,1.5,0,0,1,1.5,1.49c.24,27,17.05,51,17.22,51.27a1.51,1.51,0,0,1-1.23,2.37Z'
          />
          <path
            class='cls-3'
            d='M407.3,390.6a3,3,0,0,1-2.21-1c-.81-.89-19.76-22.16-19-59.32a3,3,0,1,1,6,.12c-.68,34.66,17.29,55,17.47,55.16a3,3,0,0,1-2.23,5Z'
          />
          <path
            class='cls-6'
            d='M238.5,129.5c10.24,16.24,58.48,123.11,73.66,152.09s50.62,20.62,50.62,20.62L373,317.1l77.15-52.44-10-24.86s22.88-10.69,16.5-45.41-20.2-48.89-31.2-64.89'
          />
          <path
            class='cls-4'
            d='M372.63,319.18,362.1,303.87c-6,1.1-37.2,5.28-51.27-21.58-6.63-12.66-19.36-39.72-32.83-68.38-17.23-36.64-35-74.52-40.77-83.61l2.54-1.6c5.83,9.24,23.68,47.21,41,83.93,13.45,28.63,26.17,55.67,32.77,68.27,14.45,27.59,47.55,20.18,48.95,19.85l1-.23,10,14.5,74.92-50.92L438.28,239l1.29-.6c.89-.41,21.74-10.69,15.65-43.78-5.33-29-15.64-43.13-25.61-56.82-1.86-2.56-3.62-5-5.35-7.49l2.48-1.7c1.7,2.48,3.45,4.88,5.3,7.42,9.69,13.32,20.68,28.41,26.13,58.05,5.64,30.66-11.26,43.39-16.09,46.34L452,265.22Z'
          />
          <path
            class='cls-7'
            d='M437.81,239.11s-58.95,7.61-74,61.46l9.67,14.95,74-51.41Z'
          />
          <circle class='cls-8' cx='431.71' cy='183.91' r='8.14' />
          <circle class='cls-8' cx='329.43' cy='225.68' r='8.14' />
          <circle class='cls-8' cx='351.79' cy='160.44' r='8.14' />
          <circle class='cls-8' cx='389.53' cy='224.91' r='8.14' />
          <path
            class='cls-3'
            d='M345.53,294.91a14.31,14.31,0,0,1-2.46-.22c-17.41-3.06-38.66-37.94-61.47-100.87a3,3,0,0,1,5.64-2c30.21,83.34,49.68,95.57,56.65,97,3.68.74,5.47-1.09,5.66-1.3a3,3,0,0,1,4.21-.35,3,3,0,0,1,.36,4.25A11.46,11.46,0,0,1,345.53,294.91Z'
          />
        </g>
        <g id='line'>
          <path
            class='cls-4'
            d='M848,131H144a2,2,0,0,1,0-4H848a2,2,0,0,1,0,4Z'
          />
          <path
            class='cls-4'
            d='M230,156H93a2,2,0,0,1,0-4H230a2,2,0,0,1,0,4Z'
          />
          <path
            class='cls-4'
            d='M902,167H794a2,2,0,0,1,0-4H902a2,2,0,0,1,0,4Z'
          />
        </g>
        <g id='_4' data-name='4'>
          <path
            class='cls-1'
            d='M378.41,666.1c-8.93-2.32-20.13-1.9-34.17,1.28L303.24,500c-5-20.42-21.39-29.33-43.83-23.83-18.64,4.56-32.06,16.7-39.89,36.07l-68,180.44-12.89,26-.14.3c-6.69,14.48-8.85,26-6.59,35.25,2,8.15,9.3,21.51,35.7,19.48,8.66-.62,22.81-3.36,43.25-8.36l84.27-18.43,9.4,37.68c.25,1,.76,3.42,1.8,9.1.92,5.07,1.7,8.93,2.41,11.81,9.45,38.58,34.48,36.86,44.8,34.33,19.48-4.77,28.22-19.4,23.38-39.15l-16.65-68c14.37-3.4,19.9-5.74,23.07-7.45,14.92-7.89,21.29-21.33,17.48-36.87C398,677,390.06,669.12,378.41,666.1Zm-120.78-72,21.59,88.16L222.1,694.85C232.08,668.27,244,634.57,257.63,594.14Z'
          />
          <path
            class='cls-2'
            d='M344.15,842.43c-12.41,0-29.44-6.1-36.92-36.63-.71-2.89-1.5-6.79-2.43-11.89-1-5.66-1.53-8-1.77-9l-9-36.26-82.85,18.11c-20.49,5-34.72,7.77-43.46,8.4-27.51,2.12-35.15-12-37.26-20.63-2.35-9.57-.16-21.42,6.68-36.23l.15-.32L150.11,692l68-180.37c8-19.88,21.8-32.31,40.93-37,11.38-2.79,21.59-2,29.53,2.34s13.56,12.15,16.12,22.59l40.65,166c13.62-2.94,24.57-3.25,33.44-1h0c12.21,3.16,20.53,11.44,23.44,23.31,4,16.25-2.67,30.3-18.24,38.54-3,1.63-8.24,3.92-21.95,7.24l16.29,66.53c5.06,20.66-4.09,36-24.48,41A41.15,41.15,0,0,1,344.15,842.43Zm-48-97.34,9.76,39.09c.25,1,.77,3.46,1.81,9.2.92,5,1.7,8.87,2.4,11.71,9.12,37.26,33.1,35.66,43,33.23,18.56-4.54,26.89-18.5,22.28-37.33l-17-69.47,1.48-.35c14.47-3.42,19.73-5.71,22.7-7.31,14.27-7.55,20.36-20.38,16.74-35.19-2.64-10.75-10.19-18.25-21.28-21.12-8.71-2.26-19.66-1.83-33.47,1.3l-1.43.32L301.78,500.31c-2.35-9.6-7.41-16.75-14.63-20.68s-16.74-4.66-27.39-2.05c-18.14,4.44-31.21,16.27-38.85,35.17l-68,180.48,0,.06-13,26.28c-6.53,14.14-8.66,25.35-6.48,34.24,1.88,7.69,8.82,20.29,34.13,18.35,8.58-.62,22.64-3.35,43-8.33Zm-76.46-48.18,1-2.59c10-26.51,21.9-60.37,35.52-100.66l1.65-4.88L281,683.44Zm37.68-97.4c-12.51,36.89-23.57,68.24-32.93,93.28l52.92-11.62Z'
          />
          <path
            class='cls-3'
            d='M163.56,704.31a5.51,5.51,0,0,1-5.11-7.52l69.24-175.51a5.5,5.5,0,1,1,10.23,4L168.68,700.83A5.5,5.5,0,0,1,163.56,704.31Z'
          />
          <path
            class='cls-4'
            d='M188,543a2.08,2.08,0,0,1-.6-.09,2,2,0,0,1-1.31-2.51c15.8-49.89,36.89-80.82,62.7-91.95,20.19-8.71,35.44-1.56,36.08-1.25a2,2,0,0,1-1.74,3.6h0c-.14-.06-14.38-6.66-32.88,1.37-16.94,7.36-41.16,28.84-60.34,89.43A2,2,0,0,1,188,543Z'
          />
        </g>
        <g id='_4-2' data-name='4'>
          <path
            class='cls-1'
            d='M871.43,671.32c-7.36-5.57-17.86-9.49-32-12L865.93,489c3.24-20.77-8.46-35.3-31.3-38.86-19-2.95-36,3.09-50.69,18l-132.2,140.4-21.89,19-.24.22c-11.76,10.8-18.18,20.62-19.64,30-1.29,8.29.3,23.43,25.46,31.71,8.23,2.76,22.34,5.67,43.14,8.92l84.87,15.4-5.81,38.4c-.16,1-.61,3.43-1.84,9.08-1.09,5-1.86,8.93-2.32,11.83-6.11,39.24,17.65,47.29,28.16,48.92,19.81,3.09,33.51-7.06,36.64-27.15l10.78-69.17c14.56,2.39,20.57,2.36,24.16,2,16.8-1.55,27.85-11.5,30.31-27.31C885.31,688.92,881,678.59,871.43,671.32ZM787.6,558.45l-14,89.68-57.56-10.39C735.51,617,759.44,590.51,787.6,558.45Z'
          />
          <path
            class='cls-2'
            d='M788.49,824.12a45.37,45.37,0,0,1-7.1-.58c-6-.93-14.57-3.57-21.18-10.83-8.17-9-10.94-22.36-8.22-39.8.45-2.91,1.24-6.91,2.33-11.92,1.23-5.62,1.67-8,1.83-9l5.59-37L678.3,699.9c-20.84-3.25-35-6.18-43.35-9-26.2-8.63-27.83-24.61-26.47-33.37,1.52-9.74,8.1-19.84,20.11-30.88l.26-.23,21.85-19L782.85,467.12c15.06-15.27,32.55-21.45,52-18.42,11.57,1.81,20.69,6.48,26.36,13.52s7.84,16.43,6.18,27L841.1,658.15c13.7,2.52,23.92,6.44,31.23,12,10,7.61,14.55,18.45,12.67,30.53-2.57,16.53-14.11,27-31.65,28.57-3.42.34-9.14.45-23.05-1.77l-10.55,67.68C816.86,813.67,805.38,824.11,788.49,824.12Zm37-373.16C810.55,451,797,457.07,785,469.2L652.78,609.62l0,0L630.6,628.91C619.13,639.46,612.86,649,611.45,658c-1.22,7.82.34,22.12,24.45,30.06,8.15,2.73,22.18,5.62,42.9,8.85l86.34,15.67-6,39.84c-.16,1-.61,3.48-1.86,9.18-1.08,5-1.85,8.89-2.29,11.74-2.58,16.5-.06,29.06,7.47,37.32,6,6.61,13.89,9,19.43,9.89,18.89,2.94,31.94-6.74,34.92-25.9l11-70.67,1.49.25c14.65,2.41,20.4,2.32,23.77,2,16.07-1.48,26.63-11,29-26,1.7-10.93-2.39-20.76-11.52-27.67-7.17-5.44-17.44-9.25-31.39-11.68l-1.45-.25,26.77-171.78c1.52-9.76-.4-18.31-5.56-24.71s-13.66-10.75-24.49-12.43A56.93,56.93,0,0,0,825.47,451Zm-50.6,198.92-61.78-11.15,1.89-2c19.36-20.62,43.41-47.28,71.49-79.26l3.41-3.88Zm-55.81-13.12,53.32,9.62,13-83.07C759.57,592.58,737.3,617.26,719.06,636.76Z'
          />
          <path
            class='cls-3'
            d='M826,661.31a6.18,6.18,0,0,1-.85-.06A5.51,5.51,0,0,1,820.6,655l26.95-172.91a5.5,5.5,0,1,1,10.87,1.7L831.47,656.66A5.5,5.5,0,0,1,826,661.31Z'
          />
          <path
            class='cls-4'
            d='M884,516a2,2,0,0,1-.34,0,2,2,0,0,1-1.63-2.31c7.18-41,.06-61.45-7.18-71.37s-16-11.3-16.11-11.31a2,2,0,0,1-1.72-2.24,2,2,0,0,1,2.23-1.72c.42.05,10.39,1.44,18.72,12.76,11.29,15.31,14,40.4,8,74.56A2,2,0,0,1,884,516Z'
          />
        </g>
      </g>
    </svg>
  </SvgDiv>
);

import React, { useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';

function KakaoMap({ lat = 33.450701, lng = 126.570667 }) {
  const kakaoMap = window.kakao;
  const mapRef = useRef(new Date());
  const handleMap = useCallback(() => {
    // const rand = new Date();
    // mapRef.current = '2';
    const container = mapRef.current;
    // const container = mapRef.current;
    if (container && kakaoMap) {
      const options = {
        center: new kakaoMap.maps.LatLng(lat, lng),
        level: 3,
      };

      new kakaoMap.maps.Map(container, options);
    }
  }, [kakaoMap, lat, lng]);

  useEffect(() => {
    handleMap();

    return () => {
      mapRef.current = null;
    };
  }, [handleMap, lat, lng]);

  return <MapDiv ref={mapRef} />;
}

export default KakaoMap;

const MapDiv = styled.div`
  width: 500px;
  height: 400px;
`;

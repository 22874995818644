import { Col, Row, Table } from 'antd';
import { useMemo } from 'react';

function MenuMng() {
  const columns = useMemo(() => {
    return [
      { title: '대메뉴', dataIndex: 'topMn' },
      { title: '메뉴', dataIndex: 'subMn' },
      { title: 'URL', dataIndex: 'url' },
      { title: '컴포넌트', dataIndex: 'compNm' },
      { title: '활성화', dataIndex: 'active' },
      { title: '설명', dataIndex: 'desc' },
    ];
  }, []);

  return (
    <>
      <Row>
        <Col></Col>
      </Row>
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Table columns={columns} />
      </Row>
    </>
  );
}

export default MenuMng;

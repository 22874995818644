export const getRemoveTag = (text) => {
  return text.replace(/(<([^>]+)>)/gi, '');
};

export const changeStringToDouble = (value) => {
  if (value.indexOf('.') > -1) {
    return parseFloat(value);
  } else {
    let len = value.length;
    // lng
    if (value.startsWith('1')) {
      let pre = value.substring(0, 3);
      let sub = value.substring(3, len);
      let mk = `${pre}.${sub}`;
      return parseFloat(mk);
    } else {
      let pre = value.substring(0, 2);
      let sub = value.substring(2, len);
      let mk = `${pre}.${sub}`;
      return parseFloat(mk);
    }
  }
};

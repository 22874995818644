import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Col,
  Drawer,
  Layout,
  Table,
  Flex,
  Image,
  Row,
  Select,
} from 'antd';
import ManualShopCard from 'components/ManualShopCard';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import MngShopCreate from './MngShopCreate';
import SimpleModal from './SimpleModal';
import {
  cityTotalList,
  deleteShopMangeByIsReady,
  doList,
  updateUserSetCity,
} from 'apis/ShopApi';
import Title from 'antd/es/typography/Title';
import { getCurrentUser } from 'store/apis/common/common_parse_api';

const { Header } = Layout;

function ManualShop({ getAllManualShopAsync, appName }) {
  const NO_IMAGE = process.env.REACT_APP_NO_IMAGE_BASE64;

  const [shop, setShop] = useState(null);
  const [shopInfo, setShopInfo] = useState(null);
  const [shopManager, setShopManager] = useState(null);

  const [open, setOpen] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [confirmObj, setConfirmObj] = useState({
    title: '',
    content: '',
    okMsg: '',
    confirm: () => {},
  });

  const [visibleSimple, setVisibleSimple] = useState(false);

  const { data: userData } = useQuery({
    queryKey: ['whoamI'],
    queryFn: getCurrentUser,
  });

  const { initDoCd, initOptionsCity } = useMemo(() => {
    // console.log('[userData]', userData);
    if (userData) {
      const doC = userData.get('doCd');
      const sdoc = sessionStorage.getItem('doCd');
      const isSame = doC === sdoc;
      if (isSame) {
        const icArr = cityTotalList.filter((item) => item.code === sdoc)[0]
          .list;
        return { initDoCd: sdoc, initOptionsCity: icArr };
      } else {
        sessionStorage.setItem('doCd', doC);
        const icArr = cityTotalList.filter((item) => item.code === doC)[0].list;
        return { initDoCd: doC, initOptionsCity: icArr };
      }
    }
    return { initDoCd: '', initOptionsCity: [] };
  }, [userData]);

  const { initCityCd, initCityNm } = useMemo(() => {
    if (userData) {
      const cuCd = userData.get('cityCd');
      const cuNm = userData.get('cityNm');
      const seCd = sessionStorage.getItem('cityCd');
      const seNm = sessionStorage.getItem('cityNm');

      const isSame = cuCd === seCd;

      if (isSame) {
        return {
          initCityCd: seCd,
          initCityNm: seNm,
        };
      } else {
        sessionStorage.setItem('cityCd', cuCd);
        sessionStorage.setItem('cityNm', cuNm);
        return {
          initCityCd: cuCd,
          initCityNm: cuNm,
        };
      }
    }
    return { initCityCd: '', initCityNm: '' };
  }, [userData]);

  const [selectedDo, setSelectedDo] = useState(initDoCd);
  const [optionsCity, setOptionCity] = useState(initOptionsCity);
  const [selectedCity, setSelectedCity] = useState(initCityCd);
  const [selectedCityNm, setSelectedCityNm] = useState(initCityNm);

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['manualShopList'],
    queryFn: () => getAllManualShopAsync(selectedCity),
  });

  const handleItemClick = useCallback((shop, item, manager) => {
    setOpen(true);
    // console.log('[ms]', manager);
    setShop(shop);
    setShopManager(manager);
    setShopInfo(item);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        title: '매장명',
        dataIndex: 'b_name',
        render: (text, row) => {
          //   console.log('[row]', row);
          return (
            <CustomStrong
              onClick={() =>
                handleItemClick(row, row['business_man'], row['managers'])
              }
            >
              {text}
            </CustomStrong>
          );
        },
      },
      {
        title: '대표이미지',
        dataIndex: 'shop_image',
        align: 'center',
        render: (text) => {
          return (
            <Image width={30} height={25} src={text} fallback={NO_IMAGE} />
          );
        },
      },
      { title: '사업장 도시', dataIndex: 'shop_sido' },
      { title: '근처 장소', dataIndex: 'nearTargetNm' },
      { title: '거리', dataIndex: 'nearTargetDistance' },
      {
        title: '수정가능',
        dataIndex: 'is_ready',
        render: (rec, _) => {
          return (
            <ReadySpan aria-readonly={rec}>{rec ? '가능' : '불가능'}</ReadySpan>
          );
        },
      },
    ];
  }, [NO_IMAGE, handleItemClick]);

  const dataSource = useMemo(() => {
    const arrs = [];

    data?.map((item) => {
      //   console.log('[item] ', item.get('managers'));
      const obj = {
        key: item['id'],
        b_name: item.get('b_name'),
        shop_sido: item.get('shop_sido'),
        nearTargetNm: item.get('nearTargetNm'),
        nearTargetDistance: item.get('nearTargetDistance'),
        is_ready: item.get('is_ready'),
        business_man: item.get('business_man'),
        managers: item.get('managers'),
        shop_image: item.get('shop_image'),
        shop_desc: item.get('shop_desc'),
        geoPoint: item.get('geoPoint'),
        is_set_portal: item.get('is_set_portal'),
        kakaoId: item.get('kakaoId'),
      };
      arrs.push(obj);
      return item;
    });
    return arrs;
  }, [data]);

  const handleCreateOpen = useCallback(() => {
    setVisibleCreate(true);
  }, []);

  const handleDelete = useCallback(() => {
    const len = selectedRowKeys.length;
    confirmObj.title = '삭제';
    confirmObj.content = `선택한 ${len}건을 삭제 하시겠습니까?`;
    confirmObj.confirm = async () => {
      const res = await deleteShopMangeByIsReady(selectedRowKeys);

      console.log('[delete keys]', selectedRowKeys);
      console.log('[delete shops]', res);
      if (Array.isArray(res) && res.length > 0) {
        refetch();
      }
      setVisibleSimple(false);
    };

    setConfirmObj(confirmObj);
    setVisibleSimple(true);
  }, [confirmObj, refetch, selectedRowKeys]);

  const rowSelection = useMemo(() => {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        console.log(
          `selectedRowKeys: ${selectedRowKeys}`,
          'selectedRows: ',
          selectedRows
        );
      },
      getCheckboxProps: (record) => ({
        disabled: record.is_ready === false,
      }),
    };
  }, []);

  const handleDoChange = useCallback((val) => {
    setSelectedDo(val);
    const filteredArr = cityTotalList.filter((item) => item.code === val)[0]
      ?.list;
    // console.log('[>>]', val, filteredArr);
    setOptionCity(filteredArr);
  }, []);

  const handleCityChange = useCallback((val) => {
    setSelectedCity(val);
  }, []);

  const handleSetCity = useCallback(() => {
    const cityNm = optionsCity.filter((item) => item.value === selectedCity)[0]
      .label;
    confirmObj.title = '도시설정';
    confirmObj.content = `선택한 ${cityNm} 으로 설정하시겠습니까?`;
    confirmObj.confirm = async () => {
      await updateUserSetCity({
        doCd: selectedDo,
        cityCd: selectedCity,
        cityNm,
      });

      sessionStorage.setItem('doCd', selectedDo);
      sessionStorage.setItem('cityCd', selectedCity);
      sessionStorage.setItem('cityNm', cityNm);
      setSelectedCityNm(cityNm);
      setVisibleSimple(false);
    };

    setConfirmObj(confirmObj);
    setVisibleSimple(true);
  }, [confirmObj, optionsCity, selectedCity, selectedDo]);

  // console.log('[init]', initDoCd, initCityCd, initCityNm);

  return (
    <>
      <Header style={headerStyle}>매장 수동입력 [{appName}] </Header>
      설정도시: {selectedCityNm}
      <hr />
      <Row>
        <Col span={18}>
          <Select
            value={selectedDo}
            defaultValue={initDoCd}
            title='구군'
            placeholder='도 선택'
            options={doList}
            style={{ width: 150 }}
            onChange={handleDoChange}
          />

          <Select
            value={selectedCity}
            defaultValue={initCityCd}
            title='도'
            placeholder='도시 선택'
            options={optionsCity}
            onChange={handleCityChange}
            style={{ width: 150 }}
          />

          <Button
            disabled={selectedCity === ''}
            type='dashed'
            onClick={handleSetCity}
          >
            도시설정
          </Button>
        </Col>
        <Col span={6}>
          <Flex wrap gap='small' justify='flex-end'>
            <Button
              disabled={selectedRowKeys.length === 0}
              onClick={handleDelete}
              type='dashed'
            >
              삭제
            </Button>
            <Button onClick={handleCreateOpen} type='default'>
              신규
            </Button>
            <Button
              disabled={selectedCity === ''}
              type='primary'
              onClick={() => refetch()}
            >
              조회
            </Button>
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Title level={3}>대상 매장정보 ({dataSource.length})</Title>{' '}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey={'key'}
            loading={isLoading}
            columns={columns}
            dataSource={dataSource}
            pagination={{ position: ['bottomCenter'] }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        </Col>
      </Row>
      {/* 아래 신규드로워 */}
      <Drawer
        title={'업체 신규등록'}
        open={visibleCreate}
        width={'80%'}
        onClose={() => setVisibleCreate(false)}
        closable
      >
        <MngShopCreate
          visible={visibleCreate}
          setVisible={setVisibleCreate}
          refetch={refetch}
        />
      </Drawer>
      {/* 아래 세부정보 드로워 */}
      <Drawer
        title={shopInfo?.request_param?.b_nm}
        open={open}
        width={'80%'}
        onClose={() => setOpen(false)}
        closable
      >
        <ManualShopCard
          shop={shop}
          item={shopInfo}
          managers={shopManager}
          open={open}
        />
      </Drawer>
      <SimpleModal
        confirmObj={confirmObj}
        setVisible={setVisibleSimple}
        visible={visibleSimple}
      />
    </>
  );
}

export default ManualShop;

const CustomStrong = styled.strong`
  cursor: pointer;
`;

const ReadySpan = styled.span`
  color: ${(props) => (props['aria-readonly'] ? 'green' : 'grey')};
`;

const headerStyle = {
  textAlign: 'left',
  color: '#111',
  height: 64,
  fontSize: 22,
  paddingInline: 48,
  lineHeight: '64px',
  backgroundColor: '#CCCCFF',
};

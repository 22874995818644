import {
  FileOutlined,
  PieChartOutlined,
  UserOutlined,
  DesktopOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem('Home', '1', <PieChartOutlined />),
  getItem('Option 2', '2', <DesktopOutlined />),
  getItem('User', 'sub1', <UserOutlined />, [
    getItem('Tom', '3'),
    getItem('Bill', '4'),
    getItem('Alex', '5'),
  ]),
  getItem('Team', 'sub2', <TeamOutlined />, [
    getItem('Team 1', '6'),
    getItem('Team 2', '8'),
  ]),
  getItem('Files', '9', <FileOutlined />),
];

const MemberLayout = ({ MemberContent }) => {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);
  const [profile, setProfile] = useState({
    nickname: '',
    profile_image: '',
    thumbnail_image: '',
  });
  // eslint-disable-next-line no-unused-vars
  const [account, setAccount] = useState({});
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const currentUser = useCallback(async () => {
    const access_token = sessionStorage.getItem('access_token');
    const refresh_token = sessionStorage.getItem('refresh_token');
    console.log('[refresh_token]', refresh_token);
    const res = await Axios.get('https://kapi.kakao.com/v2/user/me', {
      headers: { Authorization: `Bearer ${access_token}` },
    });
    console.log(' >> ', res);
    if (res.status === 200) {
      const data = res.data;
      const { email } = data.kakao_account;
      const { nickname, profile_image, thumbnail_image } = data.properties;
      setProfile(data.properties);
      setAccount(data.kakao_account);
      console.log(nickname, thumbnail_image);

      sessionStorage.setItem('nickname', nickname);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('profile_image', profile_image);
      sessionStorage.setItem('thumbnail_image', thumbnail_image);
    }
  }, []);

  const handleMenuClick = useCallback(() => {
    navigate('/member_product');
  }, [navigate]);

  useEffect(() => {
    currentUser();
  }, [currentUser]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: 'rgba(255, 255, 255, 0.2)',
          }}
        />
        <Menu
          theme='dark'
          defaultSelectedKeys={['1']}
          mode='inline'
          items={items}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout className='site-layout'>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Row>
            <Col span={18}>Handmade App Member</Col>
            <Col span={2}>{profile.nickname} 님</Col>
            <Col span={4}>
              <SDiv>
                <img
                  src={profile.thumbnail_image}
                  alt={profile.nickname}
                  className={'login-social-item'}
                />
              </SDiv>
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            margin: '0 16px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
            items={['User', 'Bill']}
          />
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            <MemberContent />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Handmade App ©2023 Created
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MemberLayout;

const SDiv = styled.div`
  .login-social-item {
    width: 54px;
    height: 54px;
    border-radius: 54px;
    padding: 3px;
    margin: 0 12px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px #d6d6d6;
  }
`;

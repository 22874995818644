import { Button } from 'antd';
import React, { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function Footer() {
  const navigate = useNavigate();

  const handleMemberLogin = useCallback(() => {
    navigate('/member');
  }, [navigate]);

  return (
    <footer className='s-footer'>
      <div className='row s-footer__content'>
        <div className='column xl-6 lg-6 md-12 s-footer__block s-footer__about'>
          <h3>About 핸드메이드 앱</h3>
          <p>
            &nbsp;핸드메이드 앱은 수제화 처럼 정성을 담아 서비스하는 앱을 만들기
            위해 최선을 다하는 응용 소프트웨어 개발 및 공급업을 하고 있습니다.
            <br />
          </p>
          <p>
            <Button onClick={handleMemberLogin} type='primary'>
              Member
            </Button>
          </p>
        </div>
        <div className='column xl-3 lg-6 md-12 s-footer__block s-footer__site-links'>
          {/* <h3>Site Links</h3>
          <ul className="link-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/blog">Journal</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <a href="https://www.dreamhost.com/r.cgi?287326">DreamHost</a>
            </li>
          </ul> */}
        </div>
      </div>

      <div className='row s-footer__bottom'>
        <div className='cloum xl1-6 lg-12'>
          <span>핸드메이드 앱</span>
          <br /> <span>대표 서정환</span>
        </div>
      </div>
      <div className='row s-footer__bottom'>
        <div className='cloum xl1-6 lg-12'>사업자 등록번호 436-30-01355</div>
      </div>
      <div className='row s-footer__bottom'>
        <div className='cloum xl1-6 lg-12'>
          통신판매업 신고번호 2023-용인기흥-1768
        </div>
      </div>
      <div className='row s-footer__bottom'>
        <div className='cloum xl1-6 lg-12'>
          <p>
            <span>
              경기도 용인시 기흥구 동백중앙로 191, 8층 (중동, 씨티프라자)
              에이867호
            </span>
          </p>
        </div>
      </div>
      <div className='row s-footer__bottom'>
        <div className='cloum xl1-6 lg-12'>
          <p>
            <LikeASpan>
              <Link to='/privacy'>개인정보처리방침</Link>
            </LikeASpan>
          </p>
        </div>
      </div>
      <div className='row s-footer__bottom'>
        <div className='column xl-6 lg-12'>
          <ul className='s-footer__social social-list'>
            <li>
              <a
                href='https://www.facebook.com/restnfeel'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path d='M20,3H4C3.447,3,3,3.448,3,4v16c0,0.552,0.447,1,1,1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325,1.42-3.592,3.5-3.592 c0.699-0.002,1.399,0.034,2.095,0.107v2.42h-1.435c-1.128,0-1.348,0.538-1.348,1.325v1.735h2.697l-0.35,2.725h-2.348V21H20 c0.553,0,1-0.448,1-1V4C21,3.448,20.553,3,20,3z'></path>
                </svg>
                <span className='u-screen-reader-text'>Facebook</span>
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/restnfeel'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path d='M19.633,7.997c0.013,0.175,0.013,0.349,0.013,0.523c0,5.325-4.053,11.461-11.46,11.461c-2.282,0-4.402-0.661-6.186-1.809 c0.324,0.037,0.636,0.05,0.973,0.05c1.883,0,3.616-0.636,5.001-1.721c-1.771-0.037-3.255-1.197-3.767-2.793 c0.249,0.037,0.499,0.062,0.761,0.062c0.361,0,0.724-0.05,1.061-0.137c-1.847-0.374-3.23-1.995-3.23-3.953v-0.05 c0.537,0.299,1.16,0.486,1.82,0.511C3.534,9.419,2.823,8.184,2.823,6.787c0-0.748,0.199-1.434,0.548-2.032 c1.983,2.443,4.964,4.04,8.306,4.215c-0.062-0.3-0.1-0.611-0.1-0.923c0-2.22,1.796-4.028,4.028-4.028 c1.16,0,2.207,0.486,2.943,1.272c0.91-0.175,1.782-0.512,2.556-0.973c-0.299,0.935-0.936,1.721-1.771,2.22 c0.811-0.088,1.597-0.312,2.319-0.624C21.104,6.712,20.419,7.423,19.633,7.997z'></path>
                </svg>
                <span className='u-screen-reader-text'>Twitter</span>
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/rest_and_feel/'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path d='M11.999,7.377c-2.554,0-4.623,2.07-4.623,4.623c0,2.554,2.069,4.624,4.623,4.624c2.552,0,4.623-2.07,4.623-4.624 C16.622,9.447,14.551,7.377,11.999,7.377L11.999,7.377z M11.999,15.004c-1.659,0-3.004-1.345-3.004-3.003 c0-1.659,1.345-3.003,3.004-3.003s3.002,1.344,3.002,3.003C15.001,13.659,13.658,15.004,11.999,15.004L11.999,15.004z'></path>
                  <circle cx='16.806' cy='7.207' r='1.078'></circle>
                  <path d='M20.533,6.111c-0.469-1.209-1.424-2.165-2.633-2.632c-0.699-0.263-1.438-0.404-2.186-0.42 c-0.963-0.042-1.268-0.054-3.71-0.054s-2.755,0-3.71,0.054C7.548,3.074,6.809,3.215,6.11,3.479C4.9,3.946,3.945,4.902,3.477,6.111 c-0.263,0.7-0.404,1.438-0.419,2.186c-0.043,0.962-0.056,1.267-0.056,3.71c0,2.442,0,2.753,0.056,3.71 c0.015,0.748,0.156,1.486,0.419,2.187c0.469,1.208,1.424,2.164,2.634,2.632c0.696,0.272,1.435,0.426,2.185,0.45 c0.963,0.042,1.268,0.055,3.71,0.055s2.755,0,3.71-0.055c0.747-0.015,1.486-0.157,2.186-0.419c1.209-0.469,2.164-1.424,2.633-2.633 c0.263-0.7,0.404-1.438,0.419-2.186c0.043-0.962,0.056-1.267,0.056-3.71s0-2.753-0.056-3.71C20.941,7.57,20.801,6.819,20.533,6.111z M19.315,15.643c-0.007,0.576-0.111,1.147-0.311,1.688c-0.305,0.787-0.926,1.409-1.712,1.711c-0.535,0.199-1.099,0.303-1.67,0.311 c-0.95,0.044-1.218,0.055-3.654,0.055c-2.438,0-2.687,0-3.655-0.055c-0.569-0.007-1.135-0.112-1.669-0.311 c-0.789-0.301-1.414-0.923-1.719-1.711c-0.196-0.534-0.302-1.099-0.311-1.669c-0.043-0.95-0.053-1.218-0.053-3.654 c0-2.437,0-2.686,0.053-3.655c0.007-0.576,0.111-1.146,0.311-1.687c0.305-0.789,0.93-1.41,1.719-1.712 c0.534-0.198,1.1-0.303,1.669-0.311c0.951-0.043,1.218-0.055,3.655-0.055c2.437,0,2.687,0,3.654,0.055 c0.571,0.007,1.135,0.112,1.67,0.311c0.786,0.303,1.407,0.925,1.712,1.712c0.196,0.534,0.302,1.099,0.311,1.669 c0.043,0.951,0.054,1.218,0.054,3.655c0,2.436,0,2.698-0.043,3.654H19.315z'></path>
                </svg>
                <span className='u-screen-reader-text'>Instagram</span>
              </a>
            </li>
            <li>
              <a
                href='https://blog.naver.com/handmadeapp'
                target='_blank'
                rel='noreferrer'
              >
                <svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'>
                  <g
                    data-name='blog blogger blogspot post web'
                    id='blog_blogger_blogspot_post_web'
                  >
                    <path d='M26,12H24V8a5,5,0,0,0-5-5H8A5,5,0,0,0,3,8V20a1,1,0,0,0,2,0V8A3,3,0,0,1,8,5H19a3,3,0,0,1,3,3v5a1,1,0,0,0,1,1h3a1,1,0,0,1,1,1v9a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3,1,1,0,0,0-2,0,5,5,0,0,0,5,5H24a5,5,0,0,0,5-5V15A3,3,0,0,0,26,12Z' />
                    <path d='M11.5,14h4a2.5,2.5,0,0,0,0-5h-4a2.5,2.5,0,0,0,0,5Zm0-3h4a.5.5,0,0,1,0,1h-4a.5.5,0,0,1,0-1Z' />
                    <path d='M11.5,23h9a2.5,2.5,0,0,0,0-5h-9a2.5,2.5,0,0,0,0,5Zm0-3h9a.5.5,0,0,1,0,1h-9a.5.5,0,0,1,0-1Z' />
                  </g>
                </svg>
                <span className='u-screen-reader-text'>naver blog</span>
              </a>
            </li>
            <li>
              <a
                href='https://www.youtube.com/channel/UCgIKzfllB-OR2HRGyLTL0cw'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  height='100%'
                  version='1.1'
                  viewBox='0 0 512 512'
                  width='100%'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g>
                    <path d='M501.299,132.766c-5.888,-22.03 -23.234,-39.377 -45.264,-45.264c-39.932,-10.701 -200.037,-10.701 -200.037,-10.701c0,0 -160.105,0 -200.038,10.701c-22.025,5.887 -39.376,23.234 -45.264,45.264c-10.696,39.928 -10.696,123.236 -10.696,123.236c0,0 0,83.308 10.696,123.232c5.888,22.03 23.239,39.381 45.264,45.268c39.933,10.697 200.038,10.697 200.038,10.697c0,0 160.105,0 200.037,-10.697c22.03,-5.887 39.376,-23.238 45.264,-45.268c10.701,-39.924 10.701,-123.232 10.701,-123.232c0,0 0,-83.308 -10.701,-123.236Z' />
                    <path d='M204.796,332.803l133.018,-76.801l-133.018,-76.801l0,153.602Z' />
                  </g>
                </svg>
                <span className='u-screen-reader-text'>youtube</span>
              </a>
            </li>
            <li>
              <a
                href='https://pf.kakao.com/_Yuuxoxj'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 48 48'
                  width='48px'
                  height='48px'
                >
                  <path
                    fill='#263238'
                    d='M24,4C12.402,4,3,11.611,3,21c0,5.99,3.836,11.245,9.618,14.273l-2.219,7.397	c-0.135,0.449,0.366,0.82,0.756,0.56l8.422-5.615C21.004,37.863,22.482,38,24,38c11.598,0,21-7.611,21-17S35.598,4,24,4z'
                  />
                  <path
                    fill='#ffca28'
                    d='M15,18H9c-0.552,0-1-0.448-1-1v0c0-0.552,0.448-1,1-1h6c0.552,0,1,0.448,1,1v0	C16,17.552,15.552,18,15,18z'
                  />
                  <path
                    fill='#ffca28'
                    d='M25,26v-9c0-0.552,0.448-1,1-1h0c0.552,0,1,0.448,1,1v9c0,0.552-0.448,1-1,1h0	C25.448,27,25,26.552,25,26z'
                  />
                  <path
                    fill='#ffca28'
                    d='M32,26v-9c0-0.552,0.448-1,1-1l0,0c0.552,0,1,0.448,1,1v9c0,0.552-0.448,1-1,1l0,0	C32.448,27,32,26.552,32,26z'
                  />
                  <path
                    fill='#ffca28'
                    d='M32.621,21.207l4.914-4.914c0.391-0.391,1.024-0.391,1.414,0v0c0.391,0.391,0.391,1.024,0,1.414	l-4.914,4.914c-0.391,0.391-1.024,0.391-1.414,0l0,0C32.231,22.231,32.231,21.598,32.621,21.207z'
                  />
                  <path
                    fill='#ffca28'
                    d='M36.078,20.665l3.708,4.717c0.341,0.434,0.266,1.063-0.168,1.404l0,0	c-0.434,0.341-1.063,0.266-1.404-0.168l-3.708-4.717c-0.341-0.434-0.266-1.063,0.168-1.404v0	C35.108,20.156,35.737,20.231,36.078,20.665z'
                  />
                  <path
                    fill='#ffca28'
                    d='M30,27h-4c-0.552,0-1-0.448-1-1v0c0-0.552,0.448-1,1-1h4c0.552,0,1,0.448,1,1v0	C31,26.552,30.552,27,30,27z'
                  />
                  <path
                    fill='#ffca28'
                    d='M23.933,25.642l-3.221-9c-0.145-0.379-0.497-0.611-0.878-0.629c-0.111-0.005-0.54-0.003-0.641-0.001	c-0.392,0.007-0.757,0.241-0.906,0.63l-3.221,9c-0.198,0.516,0.06,1.094,0.576,1.292s1.094-0.06,1.292-0.576L17.42,25h4.16	l0.486,1.358c0.198,0.516,0.776,0.773,1.292,0.576S24.131,26.157,23.933,25.642z M18.136,23l1.364-3.812L20.864,23H18.136z'
                  />
                  <path
                    fill='#ffca28'
                    d='M13,18h-2v8c0,0.552,0.448,1,1,1h0c0.552,0,1-0.448,1-1V18z'
                  />
                </svg>
              </a>
            </li>
          </ul>{' '}
          {/*<!--end s-footer__social -->*/}
        </div>
        <div className='column xl-6 lg-12'>
          <p className='ss-copyright'>
            <span>© Copyright Handmade App Corp. 2023</span>
            <span>
              Design by <a href='https://www.styleshout.com/'>StyleShout</a>
            </span>
          </p>
        </div>
        <div className='ss-go-top'>
          <a className='smoothscroll' title='Back to Top' href='#top'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width='36'
              height='36'
              fill='none'
              stroke='#ffffff'
              strokeWidth='1'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              &lt;!--! Atomicons Free 1.00 by @atisalab License -
              https://atomicons.com/license/ (Icons: CC BY 4.0) Copyright 2021
              Atomicons --&gt;
              <polyline points='17 11 12 6 7 11'></polyline>
              <line x1='12' y1='18' x2='12' y2='6'></line>
            </svg>
          </a>
        </div>{' '}
        {/*<!--end ss-go-top -->*/}
      </div>
    </footer>
  );
}

export default Footer;

const LikeASpan = styled.span`
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  color: white;
  padding-bottom: 5px;
`;
